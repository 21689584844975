import React, { useEffect, useState } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import PageHeader from '../../components/pageHeader';
import { Tooltip, notification } from 'antd';
import {
  Button,
  FormLabel,
  TextField,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DataConstant from 'src/app/constants/dataConstant';
import URLConstant from 'src/app/constants/urlConstant';
import axiosClient from 'app/configs/axiosClientConfig';
import MessageConstant from 'src/app/constants/messageConstant';
import MuiPhoneNumber from 'mui-phone-number';

const CreateAccount = (props) => {
  const { userid } = useParams();
  const [phoneNum, setPhoneNum] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const onFinish = (details) => {
    console.log('PhoneNumber', details.PhoneNumber);
    const request = {
      id: userid,
      firstName: details?.FirstName,
      lastName: details?.LastName,
      phoneNumber: phoneNum ? phoneNum : '',
      whiteLabel: details?.Whitelabel?.toLowerCase(),
    };
    if (!userid) {
      notification.error({
        message: DataConstant.error,
        description: DataConstant.SWW,
      });
      return;
    }
    if (request.firstName?.trim() == '') {
      notification.error({
        message: DataConstant.error,
        description: MessageConstant.FirstNameRequired,
      });
      return;
    }
    if (request.lastName?.trim() == '') {
      notification.error({
        message: DataConstant.error,
        description: MessageConstant.LastNameRequired,
      });
      return;
    }
    axiosClient
      .post(URLConstant.Employee.CreateUserAccount, request, true)
      .then((response) => {
        if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
          const responseDataJson = JSON.parse(response.data);
          if (
            responseDataJson &&
            responseDataJson.status === DataConstant.API_STATUS.SUCCESS
          ) {
            notification.success({
              message: DataConstant.Save,
              description: `Registration completed successfully. You can now switch your role to Admin in ${request.whiteLabel}`,
            });
            navigate(`/dashboard`);
            window.location.reload();
          } else {
            notification.error({
              message: DataConstant.error,
              description: responseDataJson?.message,
            });
          }
        } else {
          notification.error({
            message: DataConstant.error,
            description: response?.message,
          });
        }
      });
  };

  return (
    <>
      <FusePageSimple
        header={
          <PageHeader
            title="Create business account"
            showSearch={false}
            description="Create business account"
            showAddButton={false}
          />
        }
        content={
          <Paper className="w-full">
            <form
              name="userForm"
              autoComplete="off"
              onSubmit={handleSubmit(onFinish)}
            >
              <input type="hidden" name="id" value={userid} />

              <Grid container spacing={2} className="px-16 mt-0">
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">First name</FormLabel>
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                  <TextField
                    {...register('FirstName', {
                      required: MessageConstant.FirstNameRequired,
                      maxLength: {
                        value: 30,
                        message: MessageConstant.EnterMaximum30Char,
                      },
                    })}
                    fullWidth
                    autoComplete="off"
                    placeholder="Enter first name"
                    error={!!errors.FirstName}
                    helperText={
                      errors.FirstName ? errors.FirstName.message : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">Last name</FormLabel>
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                  <TextField
                    {...register('LastName', {
                      required: MessageConstant.LastNameRequired,
                      maxLength: {
                        value: 30,
                        message: MessageConstant.EnterMaximum30Char,
                      },
                    })}
                    fullWidth
                    autoComplete="off"
                    placeholder="Enter last name"
                    error={!!errors.LastName}
                    helperText={errors.LastName ? errors.LastName.message : ''}
                  />
                </Grid>

                {/* <FormLabel className="mt-16 ">Phone number</FormLabel>
                        <span className="text-[#ff0000] text-lg-start"> *</span>
                        <TextField
                        id="PhoneNumber"
                        name="PhoneNumber"
                        type="tel"
                        placeholder="Enter phone number"
                        {...register("PhoneNumber", {
                            required: MessageConstant.PhonenumberRequired,
                            pattern: {
                            value: /^(?!(\d)\1+$)[\d+-]+$/,
                            message: MessageConstant.EnterValidPhonenumber,
                            },
                            minLength: {
                            value: 10,
                            message: MessageConstant.EnterMinimum10,
                            },
                            maxLength: {
                            value: 10,
                            message: MessageConstant.EnterMaximum10,
                            },
                        })}
                        error={!!errors.PhoneNumber}
                        helperText={
                            errors.PhoneNumber
                            ? errors.PhoneNumber.message || MessageConstant.EnterValidPhonenumber
                            : ""
                        }
                        /> */}
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">Phone number</FormLabel>
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                  <MuiPhoneNumber
                    {...register('PhoneNumber')}
                    name="PhoneNumber"
                    fullWidth
                    variant="outlined"
                    defaultCountry="us"
                    value={phoneNum}
                    // onlyCountries={DataConstant.onlyCountries}
                    disableAreaCodes={true}
                    onChange={(value) => setPhoneNum(value)}
                    countryCodeEditable={false}
                    error={!!errors.PhoneNumber}
                    enableLongNumbers={true}
                    helperText={
                      errors.PhoneNumber
                        ? errors.PhoneNumber.message ||
                          MessageConstant.EnterValidPhonenumber
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">Preferred subdomain</FormLabel>
                  <label>
                    <span className="text-[#ff0000] text-lg-start"> * </span>
                    <Tooltip title={DataConstant.whitelabelInfo} className="">
                      <span>
                        {' '}
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ mt: -0.8 }}
                          color="action"
                        />{' '}
                      </span>
                    </Tooltip>
                  </label>
                  <TextField
                    {...register('Whitelabel', {
                      required: MessageConstant.SubdomainRequired,
                      pattern: {
                        value: DataConstant.whitelabelRegex,
                        message: MessageConstant.EnterValidSubdomain,
                      },
                    })}
                    fullWidth
                    autoComplete="off"
                    placeholder="Enter preferred subdomain"
                    error={!!errors.Whitelabel}
                    helperText={
                      errors.Whitelabel ? errors.Whitelabel.message : ''
                    }
                    inputProps={{
                      onInput: (e) => {
                        const lowercasedValue = (
                          '' + e.target.value
                        ).toLowerCase();
                        setValue('Whitelabel', lowercasedValue);
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    className="page-ttl px-0"
                    sx={{
                      color: '#808080',
                      mt: 1,
                    }}
                  >
                    example : yourcompany.qrrelays.com
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex items-center justify-between">
                    <label>
                      <b>*Start your account with a 14-day free trial</b>
                    </label>

                    <div className="flex items-center gap-10 justify-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="my-8 flex"
                        onClick={() => {
                          navigate(`/dashboard`);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="my-8 flex"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Paper>
        }
      />
    </>
  );
};

export default CreateAccount;
