import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import URLConstant from 'src/app/constants/urlConstant';
import axiosClient from 'app/configs/axiosClientConfig';
import DataConstant from 'src/app/constants/dataConstant';
import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import FuseLoading from '@fuse/core/FuseLoading';
import PageHeader from '../../components/pageHeader';
import store from 'app/store/index';
import { profilePic } from 'app/store/userSlice';
import MessageConstant from 'src/app/constants/messageConstant';
import MuiPhoneNumber from 'mui-phone-number';

const ManageUserProfile = () => {
  const [image, setImage] = useState({
    IsImageChange: true,
    ImagePath: URLConstant.Images.UserDefaultImagePath,
    file: '',
  });
  const hiddenFileInput = useRef(null);
  const [data, setData] = useState([]);
  const [phoneNum, setPhoneNum] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const loginDetail =
    localStorage.getItem('loginDetails') != null
      ? JSON.parse(localStorage.getItem('loginDetails'))
      : null;

  useEffect(() => {
    if (id) {
      getEmployee(id);
    } else setIsLoading(false);
  }, [id]);

  const handleImageChange = (event) => {
    if (event?.target?.files && event?.target?.files[0]) {
      const selectedFile = event?.target?.files[0];
      if (selectedFile?.size > DataConstant.imageFileSize) {
        // Display an error message or prevent further processing
        notification.warning({
          message: DataConstant.Warning,
          description: MessageConstant.ImageValidationSize,
        });
        return;
      }
      if (selectedFile?.name.toLowerCase().match(DataConstant.imagePattern)) {
        setImage({
          IsImageChange: false,
          ImagePath: URL.createObjectURL(event?.target?.files[0]),
          file: event?.target?.files[0],
        });
      } else {
        notification.warning({
          message: DataConstant.Warning,
          description: MessageConstant.ImageValidationType,
        });
      }
    }
  };

  const handleUploadButtonClick = () => {
    const formdata = new FormData();
    formdata.append('file', image.file);
    axiosClient
      .post(URLConstant.Employee.ChangeImage + '/' + id, formdata, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        setImage({ ...image, IsImageChange: true });
        notification.success({
          message: DataConstant.Save,
          description: response.message,
        });
        getEmployee(id);
      })
      .catch((error) => console.log('error', error));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const getEmployee = (employeeid) => {
    axiosClient
      .get(
        `${URLConstant.Employee.GetuserId}/${employeeid}/${loginDetail?.profile?.subscription}`
      )
      .then((response) => {
        const { user, subscriptionUser } = response?.data;
        setData(user);
        setValue('userName', user?.userName);
        setValue('firstName', subscriptionUser?.firstName);
        setValue('lastName', subscriptionUser?.lastName);
        setValue('email', user?.email);
        setValue('phoneNumber', subscriptionUser?.phoneNumber);
        setPhoneNum(subscriptionUser?.phoneNumber);
        if (user?.picture) {
          store.dispatch(
            profilePic({
              UserProfileImage: user?.picture,
            })
          );
          setImage({
            IsImageChange: true,
            ImagePath: URLConstant.Images.UserImageRootPath + user?.picture,
          });
        }
        setIsLoading(false);
      });
  };

  const onFinish = (userDetail) => {
    const UserData = { ...data };
    UserData.firstName = userDetail?.firstName;
    UserData.lastName = userDetail?.lastName;
    UserData.phoneNumber = phoneNum ?? '';
    UserData.SubscriptionId = loginDetail?.profile?.subscription;
    const requestURL = URLConstant.Employee.UpdateUser + '/' + id;
    axiosClient.post(requestURL, UserData, true).then((response) => {
      if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
        notification.success({
          message: DataConstant.Save,
          description: response?.message,
        });
        getEmployee(id);
      } else {
        notification.error({
          message: DataConstant.error,
          description: response?.message,
        });
      }
    });
  };

  // const goToCreateAccount = () => {
  //   navigate(`/createaccount/${loginDetail?.profile?.userid}`);
  // };

  if (isLoading) {
    return <FuseLoading />;
  }

  console.log(data?.userRole);

  return (
    <FusePageSimple
      header={
        <PageHeader
          title='My profile'
          showSearch={false}
          description='MyProfile'
          // showAddButton={data?.userRole === 'User' ? true : false}
          // customButtonText='Create your account'
          // onClick={goToCreateAccount}
        />
      }
      content={
        <Grid container className='px-12' spacing={2}>
          <Grid item lg={4} xs={12}>
            <form
              name='userForm'
              autoComplete='off'
              onSubmit={handleSubmit(onFinish)}
            >
              <Paper className='py-12'>
                <div
                  onClick={handleClick}
                  className='cm-upload-preview-wrapper shadow-md'
                >
                  {image?.ImagePath ? (
                    <img
                      name='picture'
                      src={`${image?.ImagePath}`}
                      alt='upload image'
                      className='cm-upload-preview'
                      {...register('picture')}
                    />
                  ) : (
                    <img
                      src={URLConstant.Images.UserDefaultImagePath}
                      alt='upload image'
                      className='cm-upload-preview'
                    />
                  )}
                  <input
                    type='file'
                    id='upload-button'
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                    ref={hiddenFileInput}
                  />
                  <Typography className='cm-choose-file-wrapper'>
                    Choose file
                  </Typography>
                </div>
                <div className='cm-u-p-overlay'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    disabled={image?.IsImageChange}
                    onClick={handleUploadButtonClick}
                    className='image-upload-button'
                  >
                    Update image
                  </Button>
                </div>
                <div className='px-16'>
                  <FormLabel className='mt-16 '>First name</FormLabel>
                  <span className='text-[#ff0000] text-lg-start'> *</span>
                  <TextField
                    id='firstName'
                    name='firstName'
                    placeholder='Enter first name'
                    className='w-full my-8'
                    {...register('firstName', {
                      required: 'First name is required',
                      maxLength: {
                        value: 30,
                        message: 'Please enter maximum 30 character',
                      },
                    })}
                    error={!!errors.firstName}
                    helperText={
                      errors.firstName ? errors.firstName.message : ''
                    }
                  />
                  <FormLabel className='mt-16 '>Last name</FormLabel>
                  <span className='text-[#ff0000] text-lg-start'> *</span>
                  <TextField
                    id='lastName'
                    name='lastName'
                    placeholder='Enter last name'
                    className='w-full my-8'
                    {...register('lastName', {
                      required: 'Last name is required',
                      maxLength: {
                        value: 30,
                        message: 'Please enter maximum 30 character',
                      },
                    })}
                    error={!!errors.lastName}
                    helperText={errors.lastName ? errors.lastName.message : ''}
                  />
                  <div className='d-none' hidden>
                    <FormLabel className='mt-16 ' hidden>
                      User name
                    </FormLabel>
                    <span className='text-[#ff0000] text-lg-start' hidden>
                      {' '}
                      *
                    </span>
                    <TextField
                      hidden
                      disabled
                      id='userName'
                      name='userName'
                      className='w-full my-8'
                      {...register('userName')}
                    />
                  </div>
                  <FormLabel className='mt-16 '>E-mail</FormLabel>
                  <span className='text-[#ff0000] text-lg-start'> *</span>
                  <TextField
                    disabled
                    id='email'
                    name='email'
                    className='w-full my-8'
                    {...register('email')}
                  />
                  {/* <Grid>
                    <FormLabel className="mt-16 ">Phone number<span className="text-[#ff0000] text-lg-start">*</span></FormLabel>
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      maxLength="50"
                      placeholder="Enter phone number"
                      className="w-full my-8"
                      {...register("phoneNumber", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^(?!(\d)\1+$)[\d+-]+$/,
                          message: "Please enter valid phone number",
                        },
                        minLength: {
                          value: 10,
                          message: "Please enter minimum 10 digits"
                        },
                        maxLength: {
                          value: 10,
                          message: "Please enter maximum 10 digits",
                        },
                      })}
                      error={!!errors.phoneNumber}
                      helperText={
                        errors.phoneNumber
                          ? errors.phoneNumber.message || "Please enter valid phone number"
                          : ""
                      }
                    />
                  </Grid> */}
                  <Grid>
                    <FormLabel className='mt-16 '>
                      Phone number
                      <span className='text-[#ff0000] text-lg-start'>*</span>
                    </FormLabel>
                    <MuiPhoneNumber
                      {...register('phoneNumber')}
                      id='phoneNumber'
                      name='phoneNumber'
                      fullWidth
                      defaultCountry='in'
                      //   onlyCountries={DataConstant.onlyCountries}
                      value={phoneNum}
                      disableAreaCodes={true}
                      variant='outlined'
                      onChange={(value) => setPhoneNum(value)}
                      countryCodeEditable={false}
                      error={!!errors.phoneNumber}
                      enableLongNumbers={true}
                      helperText={
                        errors.phoneNumber
                          ? errors.phoneNumber.message ||
                            MessageConstant.EnterValidPhonenumber
                          : ''
                      }
                    />
                  </Grid>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='my-8'
                  >
                    Update
                  </Button>
                </div>
              </Paper>
            </form>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Paper className='h-full'>
              <p className='py-8 px-12'>Overview</p>
            </Paper>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ManageUserProfile;
