import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Fab,
  LinearProgress,
  TextField,
  Grid,
  Divider,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareIcon from '@mui/icons-material/Share';
import WorkIcon from '@mui/icons-material/Work';
import LanguageIcon from '@mui/icons-material/Language';
import PopupDialog from './PopupDialog';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import URLConstant from 'src/app/constants/urlConstant';
import axiosClient from 'app/configs/axiosClientConfig';
import DataConstant from 'src/app/constants/dataConstant';
import MessageConstant from 'src/app/constants/messageConstant';
import { notification } from 'antd';
import ShareDialogContent from './ShareDialogContent';
import _ from 'lodash';

const VCardPreview = ({ userInfo, isPreview, setLoading, loading }) => {
  const emailRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [loading]);

  const [openDialog, setOpenDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });

  const [openCallDialog, setOpenCallDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });
  const [openEmailDialog, setOpenEmailDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });
  const [openShareDialog, setOpenShareDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });
  const [openAddContactDialog, setOpenAddContactDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });
  const [openSendByEmailDialog, setOpenSendByEmailDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });
  const [openSaveToPhoneDialog, setOpenSaveToPhoneDialog] = useState({
    open: false,
    title: '',
    message: '',
    icon: null,
  });

  const handleOpenCallDialog = () => {
    setOpenCallDialog({
      open: true,
      title: 'CALL',
      message: 'Click this button to call your mobile number',
      icon: CallIcon,
    });
  };

  const handleOpenEmailDialog = () => {
    setOpenEmailDialog({
      open: true,
      title: 'EMAIL',
      message: 'Click this button to send an email',
      icon: EmailIcon,
    });
  };

  const handleOpenShareDialog = () => {
    if (isPreview) {
      setOpenShareDialog({
        open: true,
        title: 'SHARE',
        message: 'Click this button to share your contact info.',
        icon: ShareIcon,
      });
    } else {
      setOpenShareDialog({
        open: true,
        message: <ShareDialogContent userInfo={userInfo} />,
      });
    }
  };

  const handleDialogClose = () => {
    setOpenDialog({ open: false, title: '', message: '', icon: null });
  };

  //call Dialog Close
  const handleCallDialogClose = () => {
    setOpenCallDialog({ open: false, title: '', message: '', icon: null });
  };

  //email Dialog Close
  const handleEmailDialogClose = () => {
    setOpenEmailDialog({ open: false, title: '', message: '', icon: null });
  };

  //share Dialog Close
  const handleShareDialogClose = () => {
    setOpenShareDialog({ open: false, title: '', message: '', icon: null });
  };

  //Add Contact Dialog Close
  const handleAddContactDialogClose = () => {
    setOpenAddContactDialog({
      open: false,
      title: '',
      message: '',
      icon: null,
    });
  };

  //Add Send By Email Dialog Close
  const handleSendByEmailDialogClose = () => {
    setOpenSendByEmailDialog({
      open: false,
      title: '',
      message: '',
      icon: null,
    });
  };

  //Add Save To Phone Dialog Close
  const handleOpenSaveToPhoneDialogClose = () => {
    setOpenSaveToPhoneDialog({
      open: false,
      title: '',
      message: '',
      icon: null,
    });
  };

  const BusinessCardContactEmail = () => {
    if (emailRef?.current === null) {
      notification.error({
        message: MessageConstant.error,
        description: 'Please enter email.',
      });
      return;
    } else if (!emailRef?.current?.match(DataConstant.EmailRegex)) {
      notification.error({
        message: MessageConstant.error,
        description: 'Please enter valid email.',
      });
      return;
    }
    const url = `${URLConstant.QRCode.BusinessCardContactEmail}?QRCodeId=${userInfo?.id}&Email=${emailRef?.current}`;

    axiosClient
      .post(url)
      .then((response) => {
        if (
          response != null &&
          response.status.toLowerCase() ==
            DataConstant.API_STATUS.SUCCESS.toLowerCase()
        ) {
          handleSendByEmailDialogClose();
          setOpenDialog({
            open: true,
            message: (
              <div>
                <Typography color={'rgb(54, 193, 125)'}>
                  Email was sent
                </Typography>
                <Typography>
                  Please look in your inbox, the email will arrive Shortly.
                </Typography>
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Loader Component
  const Loader = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        background: '#f5f5f5',
        animation: 'fadeIn 1s ease-in-out',
      }}
    >
      <style>{`
    @keyframes scaleUp {
      0% {
        transform: scale(0.5); // Start small
      }
      100% {
        transform: scale(1); // End at normal size
      }
    }
  `}</style>
      {userInfo && userInfo.backGroundImage.selectedImageUrl ? (
        <img
          src={userInfo.backGroundImage.selectedImageUrl}
          alt="Loading..."
          style={{
            width: '100px',
            height: 'auto',
            animation: 'scaleUp 3s forwards',
          }}
        />
      ) : (
        <img
          src={require('../../assets/img/defaultscreenlogo.png')}
          alt="Loading..."
          style={{
            width: '100px',
            height: 'auto',
            animation: 'scaleUp 3s forwards',
          }}
        />
      )}
    </Box>
  );

  const handleSendByEmail = () => {
    if (isPreview === false) {
      setOpenSendByEmailDialog({
        open: true,
        message: (
          <>
            <Typography fontWeight={'bold'} variant="h5" gutterBottom>
              Send by Email.
            </Typography>
            <Typography mb={2}>Send contact details using email.</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ minWidth: '280px' }}
                    placeholder="Enter Email Address"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      emailRef.current = e.target.value;
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => {
                  BusinessCardContactEmail();
                }}
              >
                Send
              </Button>
            </Box>
          </>
        ),
        icon: EmailIcon,
      });
    } else {
      setOpenSendByEmailDialog({
        open: true,
        title: 'Send by Email',
        message: 'You can share business card via email',
        icon: EmailIcon,
      });
    }
  };

  const handleSaveToPhone = () => {
    if (isPreview) {
      setOpenSaveToPhoneDialog({
        open: true,
        title: 'Save to My Phone',
        message: 'You can download or share your business card',
        icon: SaveAltIcon,
      });
    } else {
      const vCardData = `
BEGIN:VCARD
VERSION:3.0
N:${userInfo?.lastName};${userInfo?.firstName};;;
FN:${userInfo?.firstName} ${userInfo?.lastName}
TEL;TYPE=CELL:${userInfo?.mobileNo}
EMAIL;TYPE=INTERNET:${userInfo?.email}
ORG:${userInfo?.company}
ADR;TYPE=WORK:;;${userInfo?.address}
URL:${userInfo?.website}
NOTE:${userInfo?.yourJob}
END:VCARD
  `.trim();

      // Create a blob from the vCard data
      const blob = new Blob([vCardData], {
        type: 'text/vcard;charset=utf-8',
      });
      const url = URL.createObjectURL(blob);

      // Create a temporary link to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `vcard_${userInfo?.firstName}_${userInfo?.lastName}.vcf`; // Name the file like vcard_first_last.vcf
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  const handleOpenAddContactDialog = () => {
    setOpenAddContactDialog({
      open: true,
      message: (
        <div>
          <Typography fontWeight={'bold'} variant="h5" gutterBottom>
            Save contact data
          </Typography>
          <Typography mb={2}>
            How would you like to save contact data?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              startIcon={<EmailIcon />}
              onClick={handleSendByEmail}
              sx={{ textTransform: 'none' }}
            >
              Send by Email
            </Button>

            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleSaveToPhone}
              startIcon={<SaveAltIcon />}
              sx={{ textTransform: 'none' }}
            >
              Save to my phone
            </Button>
          </Box>
        </div>
      ),
      icon: null,
    });
  };

  const handleCallClick = () => {
    if (isPreview) {
      handleOpenCallDialog();
    } else {
      window.location.href = `tel:${userInfo?.mobileNo}`;
    }
  };

  const SocialLink = ({ linkDetails }) => {
    if (
      _.toLower(linkDetails?.name) != 'call' &&
      _.toLower(linkDetails?.name) != 'mail' &&
      !linkDetails?.link?.includes('https://')
    ) {
      linkDetails.link = 'https://' + linkDetails?.link;
    }
    return (
      <>
        {_.toLower(linkDetails?.name) == 'call' && (
          <div style={{ margin: '10px' }}>
            <a
              href={'tel:' + linkDetails.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ alignItems: 'center' }}
            >
              <img
                src={linkDetails?.imgSrc}
                alt={linkDetails?.name}
                style={{ width: '30px', height: '30px', marginRight: '8px' }}
              />
            </a>
          </div>
        )}
        {_.toLower(linkDetails?.name) == 'mail' && (
          <div style={{ margin: '10px' }}>
            <a
              href={'mailto:' + linkDetails?.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ alignItems: 'center' }}
            >
              <img
                src={linkDetails?.imgSrc}
                alt={linkDetails?.name}
                style={{ width: '30px', height: '30px', marginRight: '8px' }}
              />
            </a>
          </div>
        )}
        {_.toLower(linkDetails?.name) != 'call' &&
          _.toLower(linkDetails?.name) != 'mail' && (
            <div style={{ margin: '10px' }}>
              <a
                href={`${linkDetails?.link?.toLowerCase()}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ alignItems: 'center' }}
              >
                <img
                  src={linkDetails?.imgSrc}
                  alt={linkDetails?.name}
                  style={{ width: '30px', height: '30px', marginRight: '8px' }}
                />
              </a>
            </div>
          )}
      </>
    );
  };

  return (
    <div id="v-card-preview" className="cm-phone-preview-wrapper">
      <Box className="vcard-container">
        {loading ? (
          <>
            <Box className="vcard-loading">
              <LinearProgress color="primary" />
              <Loader />
            </Box>
          </>
        ) : (
          <>
            {/* Header Section */}
            <Box
              className="vcard-header"
              sx={{
                background: userInfo?.useGradient
                  ? `linear-gradient(${userInfo.primaryColor}, ${userInfo?.gradientColor})`
                  : userInfo?.primaryColor,
              }}
            >
              {userInfo?.profilePic &&
                userInfo?.profilePic?.selectedImageUrl && (
                  <img
                    className="vcard-profile-pic"
                    src={userInfo?.profilePic.selectedImageUrl}
                    alt="Profile"
                  />
                )}
              <Typography className="word-break" variant="h6">
                {userInfo?.firstName
                  ? `${userInfo?.firstName} ${userInfo?.lastName || ''}`
                  : ''}
              </Typography>
              <Typography className="word-break" variant="body2">
                {userInfo?.yourJob}
              </Typography>

              {/* Horizontal Buttons in Header */}
              <Box className="buttons-icon-upside vcard-buttons">
                <Button
                  className="vcard-button"
                  onClick={handleCallClick}
                  startIcon={<CallIcon />}
                  sx={{
                    backgroundColor: userInfo?.useGradient
                      ? `linear-gradient(${userInfo?.primaryColor}, ${userInfo?.gradientColor})`
                      : userInfo?.primaryColor,
                  }}
                >
                  Call
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Button
                  className="vcard-button"
                  onClick={() => {
                    if (isPreview) {
                      handleOpenEmailDialog();
                    } else {
                      window.open(
                        `mailto:${userInfo?.email}?subject=From my vCard`
                      );
                    }
                  }}
                  startIcon={<EmailIcon />}
                  sx={{
                    backgroundColor: userInfo?.useGradient
                      ? `linear-gradient(${userInfo?.primaryColor}, ${userInfo?.gradientColor})`
                      : userInfo?.primaryColor,
                  }}
                >
                  Email
                </Button>
              </Box>
            </Box>

            {/* Contact Information Section */}
            <Box className="vcard-info-section">
              {/* Contact Information Section */}
              <Box
                sx={{
                  mb: 1,
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  color: '#b3b4bb',
                  p: 1,
                  height: '100%',
                }}
              >
                {userInfo?.summary && (
                  <>
                    <Box className="vcard-info-item">
                      <Box>
                        <Typography
                          sx={{ color: '#323032' }}
                          ml={3.8}
                          variant="body1"
                        >
                          {userInfo?.summary}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
                <Box className="vcard-info-item">
                  <PhoneIcon sx={{ mr: 1 }} />
                  <Box>
                    <Typography
                      sx={{ color: '#323032' }}
                      className="word-break"
                      variant="body1"
                    >
                      {userInfo?.mobileNo}
                    </Typography>
                    <Typography variant="caption">Mobile</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    mb: 1,
                  }}
                >
                  {userInfo?.phone && (
                    <>
                      <Typography
                        sx={{ color: '#323032' }}
                        ml={3.8}
                        variant="body1"
                      >
                        {userInfo.phone}
                      </Typography>
                      <Typography ml={3.8} variant="caption">
                        Phone
                      </Typography>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    mb: 1,
                  }}
                >
                  {userInfo?.fax && (
                    <>
                      <Typography
                        sx={{ color: '#323032' }}
                        ml={3.8}
                        variant="body1"
                      >
                        {userInfo.fax}
                      </Typography>
                      <Typography ml={3.8} variant="caption">
                        Fax
                      </Typography>
                    </>
                  )}
                </Box>

                {userInfo?.email && (
                  <>
                    <Divider />
                    <Box className="vcard-info-item">
                      <EmailIcon sx={{ mr: 1 }} />
                      <Box>
                        <Typography
                          sx={{ color: '#323032' }}
                          className="word-break"
                          variant="body1"
                        >
                          {userInfo?.email}
                        </Typography>
                        <Typography variant="caption">Email</Typography>
                      </Box>
                    </Box>
                  </>
                )}
                {(userInfo?.company || userInfo?.yourJob) && (
                  <>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <WorkIcon sx={{ mr: 1 }} />
                      <Box>
                        {userInfo?.company && (
                          <Typography
                            sx={{ color: '#323032' }}
                            className="word-break"
                            variant="body1"
                          >
                            {userInfo.company}
                          </Typography>
                        )}
                        {userInfo?.yourJob && (
                          <Typography className="word-break" variant="caption">
                            {userInfo.yourJob}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
                {userInfo?.website && (
                  <>
                    <Divider />
                    <Box className="vcard-info-item">
                      <LanguageIcon sx={{ mr: 1 }} />
                      <Box>
                        <Typography
                          sx={{ color: '#323032' }}
                          className="word-break"
                          variant="body1"
                        >
                          {userInfo.website}
                        </Typography>
                        <Typography variant="caption">Website</Typography>
                      </Box>
                    </Box>
                  </>
                )}

                {userInfo?.socialLinks && userInfo?.socialLinks?.length > 0 && (
                  <>
                    <Divider />
                    <Typography ml={3.8}>Social Media</Typography>
                    <div className="vcard-social-media">
                      {userInfo.socialLinks.map((link, index) => (
                        <SocialLink key={index} linkDetails={link} />
                      ))}
                    </div>
                  </>
                )}
              </Box>
            </Box>

            {userInfo?.sharepage === true && (
              <Fab
                onClick={handleOpenShareDialog}
                sx={{
                  position: 'absolute',
                  bottom: '80px',
                  right: '15px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  background: '#fff',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  zIndex: 2,
                }}
              >
                <ShareIcon />
              </Fab>
            )}

            {/* Share Button */}
            <Fab
              onClick={handleOpenAddContactDialog}
              sx={{
                position: 'absolute',
                bottom: '15px',
                right: '15px',
                background: userInfo.useGradient
                  ? `linear-gradient(${userInfo.primaryColor}, ${userInfo.gradientColor})`
                  : userInfo.buttonColor,
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              <PersonAddIcon />
            </Fab>
          </>
        )}
      </Box>
      <PopupDialog
        open={openDialog.open}
        onClose={handleDialogClose}
        title={openDialog.title}
        message={openDialog.message}
        icon={openDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
        hideGotIt={true}
      />
      <PopupDialog
        open={openCallDialog.open}
        onClose={handleCallDialogClose}
        title={openCallDialog.title}
        message={openCallDialog.message}
        icon={openCallDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
      />
      <PopupDialog
        open={openEmailDialog.open}
        onClose={handleEmailDialogClose}
        title={openEmailDialog.title}
        message={openEmailDialog.message}
        icon={openEmailDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
      />
      <PopupDialog
        open={openShareDialog.open}
        onClose={handleShareDialogClose}
        title={openShareDialog.title}
        message={openShareDialog.message}
        icon={openShareDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
        hideGotIt={true}
      />
      <PopupDialog
        open={openAddContactDialog.open}
        onClose={handleAddContactDialogClose}
        title={openAddContactDialog.title}
        message={openAddContactDialog.message}
        icon={openAddContactDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
        hideGotIt={true}
      />
      <PopupDialog
        open={openSendByEmailDialog.open}
        onClose={handleSendByEmailDialogClose}
        title={openSendByEmailDialog.title}
        message={openSendByEmailDialog.message}
        icon={openSendByEmailDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
        hideGotIt={true}
      />
      <PopupDialog
        open={openSaveToPhoneDialog.open}
        onClose={handleOpenSaveToPhoneDialogClose}
        title={openSaveToPhoneDialog.title}
        message={openSaveToPhoneDialog.message}
        icon={openSaveToPhoneDialog.icon}
        bgColor={userInfo?.primaryColor}
        iconColor="#fff"
      />
    </div>
  );
};

export default VCardPreview;
